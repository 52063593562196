import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import CenterContentHome from "../../components/common/CenterContentHeader";
import { RequestForm } from "../../components/form";

const topImage = require("../../assets/images/10-pages/con-hr-support/con_hr_support_header.png");
const card_one = require("../../assets/images/10-pages/con-hr-support/con_hr_support_sec_1_1.png");
const card_two = require("../../assets/images/10-pages/con-hr-support/con_hr_support_sec_1_2.png");
const card_three = require("../../assets/images/10-pages/con-hr-support/con_hr_support_sec_1_3.png");
const card_four = require("../../assets/images/10-pages/con-hr-support/con_hr_support_sec_2_1.png");
const card_five = require("../../assets/images/10-pages/con-hr-support/con_hr_support_sec_2_2.png");
const card_six = require("../../assets/images/10-pages/con-hr-support/con_hr_support_sec_2_3.png");
const card_7 = require("../../assets/images/10-pages/con-hr-support/con_hr_support_sec_3_1.png");
const card_8 = require("../../assets/images/10-pages/con-hr-support/con_hr_support_sec_3_2.png");
const card_9 = require("../../assets/images/10-pages/con-hr-support/con_hr_support_sec_3_3.png");

export const dataProtection = [
  {
    textHeader: "User Experience",
    bg:"bg_white",
    cards: [
      {
        image: card_one,
        header: "Deliver better HR experience remotely",
        content:
          "Employee engagement is more important than ever now. We can help you streamline HR services to employees with our advanced conversational ai platform.",

        uuid: "a",
        
      },
      {
        image: card_two,
        header: "Go remote anywhere, anytime",
        content:
          "Employees no longer are tied to their workplace; then why should your HR services be. Delight your employees from the comfort of their favorite apps like Slack or Teams.",
        
      },
      {
        image: card_three,
        header: "Everything employees will most need, in one place",
        content:
          "With our conversational ai for HR, you can do more than just automating FAQs — whether it’d be a query for policy search or resolving an expense claim, employees only have to engage with our chatbot.",
      },
    ],
  },

  {
    textHeader: "HR Workflow Automation",
    bg:"bg_grey",
    cards: [
      {
        image: card_four,
        header: "Empower your HR teams by automating HR processes",
        content:
          "Deliver instant resolutions to employee enquiries and requests using HR workflows automation. Automate time-consuming tasks such as PTO, expense claims, onboarding/offboarding etc. in few clicks.",
        uuid: "a",
      },
      {
        image: card_five,
        header: "Use pre-built HR workflows from the marketplace",
        content:
          "Workativ comes with over 400+ pre-built workflows for popular apps, that are ready to use from the marketplace. You can go live in minutes.",
      },
      {
        image: card_six,
        header: "Integrate Chatbot with HR apps in a click",
        content:
          "Our platform comes with pre-built and secure one-click integrations to HR apps, so you only need to sign-in and connect, saving a ton on time and effort.",
      },
    ],
  },
  {
    textHeader: "Productivity, Performance and Scale",
    bg:"bg_white",
    cards: [
      {
        image: card_7,
        header: "Lean HR budgets. Scale with Workativ",
        content:
          "With Workativ, companies can save costs from day one. No upfront setup fee, no installations. Workativ can deliver ROI from the day one.",
        uuid: "a",
      },
      {
        image: card_8,
        header: "Modern HR engagement centered around messaging",
        content:
          "Be rid of logging in and switching between multiple apps constantly for employees to take care of their HR-related queries. Connect workativ with tools like Slack, Microsoft Teams, Zenefits, and several others to deliver support. As simple as that.",
      },
      {
        image: card_9,
        header: "Free up more time for your HR",
        content:
          "Save HR from repetitive questions monopolizing their time. Personalized employee support automation lets every employee feel special, giving HR staff the time to prioritize high-value growth initiatives.",
      },
    ],
  },
  
];
console.log("dataProtection", dataProtection);
export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Conversational AI for HR Support | Automate HR support with Conversational AI"
        description="Use conversational ai to automate HR support. Deliver instant resolution to employee queries and process requests and transform your workplace support."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={topImage}
        ogTitle="Conversational AI for HR Support | Automate HR support with Conversational AI"
        ogDescription="Use conversational ai to automate HR support. Deliver instant resolution to employee queries and process requests and transform your workplace support."
      />
      <Container>
        <Layout backgroundColor={"bg_header_it_support"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"bg_header_it_support"}
            altImage={"Conversational hr Support"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
              Break the email barrier. Embrace the chat resolution
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
              Deliver engaging experience using conversational ai for HR support that lets employees resolve repetitive HR enquires and requests on their own.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>{" "}
          <CenterContentHome>
            <CenterContentHome.Header>
            What makes our Conversational AI for HR Support solution different?
            </CenterContentHome.Header>
          </CenterContentHome>
          <div className="security_wrapper _wrapper_support">
            {dataProtection.map((data, index) => {
              // console.log("rrr", dataProtection);
              if (index % 2 == 0) {
                return (
                  <div className="resources_card">
                    <section className="whitepaper_cards">
                      <div className="container">
                        <div className="row">
                          <h4>{data.textHeader}</h4>
                          <ul className="cards">
                            {data.cards.map((cards) => (
                              <li className="cards__item">
                                <div className="card">
                                  <div className="card__image">
                                    <img
                                      src={cards.image}
                                      alt={cards.header}
                                    ></img>
                                  </div>
                                  <div className="card__content">
                                    <div className="card__title">
                                      {cards.header}
                                    </div>
                                    <p className="card__text">
                                      {cards.content}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </section>
                  </div>
                );
              }
              return (
                <div className="resources_card bg_ccc">
                  <section className="whitepaper_cards">
                    <div className="container">
                      <div className="row">
                        <h4>{data.textHeader}</h4>
                        <ul className="cards">
                          {data.cards.map((cards) => (
                            <li className="cards__item">
                              <div className="card">
                                <div className="card__image">
                                  <img
                                    src={cards.image}
                                    alt={cards.header}
                                  ></img>
                                </div>
                                <div className="card__content">
                                  <div className="card__title">
                                    {cards.header}
                                  </div>
                                  <p className="card__text">{cards.content}</p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              );
            })}
          </div>
          <Example />
          <div className="mb_footer">
            <RequestForm isFooterForm={true} />
          </div>
        </Layout>
      </Container>
    </>
  );
}
function Example() {
  return (
    <React.Fragment>
        <div className="mob_accordian">
          <div className="container">
      {dataProtection.map((data) => (
        <Accordion className={data.bg}  preExpanded={["a"]}>
             <h4>{data.textHeader}</h4>
          {data.cards.map((cards) => (
            <AccordionItem uuid={cards.uuid}>
              <AccordionItemHeading>
                <AccordionItemButton>{cards.header}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{cards.content}</p>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      ))}
      </div>
      </div>
      </React.Fragment>
  );
}
